import React, { useState, useEffect } from "react";
import {
  getPremiumUsers,
  removePremiumStatus,
  updatePremiumStatus,
} from "../../helpers/firebase";
import "../../styles/Table.css";
import "./PremiumTable.css";
import ExpandedTagColumn from "./ExpandedPremiumColumn";
import { Timestamp } from "firebase/firestore";

const PremiumTable = ({ clubs = [] }) => {
  const [expandedCols, setExpandedCols] = useState({});
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    await getPremiumUsers()
      .then((data) => {
        setUsers(data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const onToggleRow = (id, checked) => {
    setExpandedCols({
      ...expandedCols,
      [id]: checked,
    });
  };
  const renderRows = () => {
    return clubs.map((row, index) => renderHeaderRow(row, index + 1));
  };

  const userToUpdate = async (player, timestamp, premium) => {
    let userToUpdate;
    let updatedUser;
    let newTimeStamp = new Timestamp(timestamp ? timestamp / 1000 : 0, 0);

    if (premium) {
      userToUpdate = true;
      updatedUser = {
        ...premium,
        premium_until: newTimeStamp,
      };
    } else {
      userToUpdate = users.find((user) => user.email === player.email);
      updatedUser = {
        ...userToUpdate,
        premium_until: newTimeStamp,
      };
    }
    if (!userToUpdate) {
      alert("User is not created properly");
      return;
    }
    let updatedUserResponse;
    if (timestamp === false) {
      return await removePremiumStatus(updatedUser)
        .then((res) => {
          fetchUsers();
          return true;
        })
        .catch((e) => {
          console.error("ERROR", e);
        });
    }
    return await updatePremiumStatus(updatedUser)
      .then((res) => {
        fetchUsers();
        return true;
      })
      .catch((e) => {
        console.error("ERROR", e);
      });
  };

  const renderHeaderRow = (teamName, index) => {
    return (
      <li key={index}>
        <input
          type="checkbox"
          id={`list-item-${index}`}
          onChange={(evt) => onToggleRow(index, evt.target.checked)}
        />
        <label
          htmlFor={`list-item-${index}`}
          className={index === 1 ? "first" : ""}
        >
          {teamName}
        </label>
        <ExpandedTagColumn
          teamName={teamName}
          isExpanded={expandedCols[index]}
          users={users}
          setUsers={setUsers}
          userToUpdate={userToUpdate}
        />
      </li>
    );
  };

  return (
    <div className="wrapper">
      <ul>{renderRows()}</ul>
    </div>
  );
};

export default PremiumTable;
