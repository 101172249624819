import React, { useState, useEffect } from "react";
import { formatDate } from "../../helpers/dateHelpers";

const User = ({ player, premium, userToUpdate }) => {
  let initialDate = new Date();
  initialDate.setMonth(initialDate.getMonth() + 1);
  const [expand, setExpand] = useState(false);
  const [openPremium, setOpenPremium] = useState(null);
  const [activePremiumTimeStamp, setActivePremiumTimeStamp] =
    useState(initialDate);
  const [bouncedTimestamp, setBouncedTimestamp] = useState(initialDate);
  const [isPremium, setIsPremium] = useState(false);

  let name = player.name;
  if (!player.name) {
    if (player.first_name && player.last_name) {
      name = `${player.first_name} ${player.last_name}`;
    } else if (player.first_name) {
      name = player.first_name;
    } else if (player.last_name) {
      name = player.last_name;
    } else {
      name = "Name not provided";
    }
  }

  useEffect(() => {
    if (premium && premium.premium_until?.seconds) {
      const status =
        new Date().getTime() < premium.premium_until.seconds * 1000;
      if (status) {
        setIsPremium(true);
        setActivePremiumTimeStamp(premium.premium_until.seconds * 1000);
        setBouncedTimestamp(premium.premium_until.seconds * 1000);
      }
    }
  }, [expand]);

  return (
    <li>
      <p className="paragraph">{name}</p>
      {isPremium && (
        <p className="paragraph golden">
          Premium until {formatDate(bouncedTimestamp)}
        </p>
      )}
      <button
        style={{
          position: "absolute",
          right: "10px",
        }}
        onClick={() => setExpand(!expand)}
      >
        {expand ? "-" : "+"}
      </button>
      {expand && (
        <div>
          <p className="paragraph">Set player as premium</p>
          <input
            type="date"
            name="datepicker"
            id="datepicker"
            value={formatDate(activePremiumTimeStamp, "y-m-d")}
            onChange={(e) => {
              setActivePremiumTimeStamp(new Date(e.target.value).getTime());
            }}
          />
          <br />
          <span
            className="paragraph golden"
            style={{ marginLeft: "10px", cursor: "pointer" }}
            onClick={() =>
              userToUpdate(player, activePremiumTimeStamp, premium)
            }
          >
            + update
          </span>
          {isPremium && (
            <span
              className="paragraph red"
              style={{ marginLeft: "10px", cursor: "pointer" }}
              onClick={() => userToUpdate(player, false, premium)}
            >
              - remove premium
            </span>
          )}
        </div>
      )}
    </li>
  );
};

export default User;
