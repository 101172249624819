import React, { useEffect, useState } from "react";
import { formatDate } from "../../helpers/dateHelpers";
import { getTeamPlayers } from "../../helpers/firebase";
import User from "./User";

const Teams = ({
  club,
  teamName,
  isExpanded,
  users,
  userToUpdate,
  loading,
}) => {
  const { data } = club;
  let initialDate = new Date();
  initialDate.setMonth(initialDate.getMonth() + 1);
  const [players, setPlayers] = useState([]);

  const [expand, setExpand] = useState(false);
  const [activePremiumTimeStamp, setActivePremiumTimeStamp] =
    useState(initialDate);
  const [bouncedTimestamp, setBouncedTimestamp] = useState(initialDate);
  const [isPremium, setIsPremium] = useState(false);
  const coachName = data?.coachName || "Coach name not provided";
  const coachUser = users.find(
    (user) => user.teamName === data.name && user.userType === "coach"
  );

  useEffect(() => {
    if (isExpanded) {
      fetchPlayers().then((res) => {});
      if (coachUser && coachUser.premium_until?.seconds) {
        const status =
          new Date().getTime() < coachUser.premium_until.seconds * 1000;
        if (status) {
          setIsPremium(true);
          setActivePremiumTimeStamp(coachUser.premium_until.seconds * 1000);
          setBouncedTimestamp(coachUser.premium_until.seconds * 1000);
        }
      } else {
        setIsPremium(false);
      }
    }

    return () => {
      setPlayers([]);
    };
  }, [isExpanded, users, coachUser]);
  const fetchPlayers = async () => {
    await getTeamPlayers(teamName, club.id)
      .then((data) => {
        setPlayers(data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const renderPlayers = () => {
    return (
      players &&
      players.map((player, index) => {
        let premium =
          users.find(
            (user) =>
              (user.email === player.email || user.id === player.id) &&
              user.premium_until
          ) || false;
        return (
          <User
            player={player}
            premium={premium}
            key={index}
            userToUpdate={userToUpdate}
          />
        );
      })
    );
  };
  return (
    <li>
      <p>{data.name || "Team name not provided"}</p>
      <p className="paragraph">
        Coach: {coachName}{" "}
        <span
          style={{
            position: "absolute",
            right: "20px",
            color: "black",
            cursor: "pointer",
          }}
          onClick={() => setExpand(!expand)}
        >
          {expand ? "-" : "+"}
        </span>
      </p>
      {isPremium && (
        <p className="paragraph golden">
          Premium until {formatDate(bouncedTimestamp)}
        </p>
      )}

      {expand && (
        <div>
          <p className="paragraph">Set coach as premium</p>
          <input
            type="date"
            name="datepicker"
            id="datepicker"
            value={formatDate(activePremiumTimeStamp, "y-m-d")}
            onChange={(e) => {
              setActivePremiumTimeStamp(new Date(e.target.value).getTime());
            }}
          />
          <br />
          <span
            className="paragraph golden"
            style={{ marginLeft: "10px", cursor: "pointer" }}
            onClick={() =>
              userToUpdate(false, activePremiumTimeStamp, coachUser)
            }
          >
            + update coach
          </span>
          {isPremium && (
            <span
              className="paragraph red"
              style={{ marginLeft: "10px", cursor: "pointer" }}
              onClick={() => userToUpdate(false, false, coachUser)}
            >
              - remove coach premium
            </span>
          )}
        </div>
      )}
      <div>
        <ul>
          {players && players.length > 0 ? (
            renderPlayers()
          ) : (
            <p className="paragraph">No Players</p>
          )}
        </ul>
      </div>
    </li>
  );
};

export default Teams;
