import React, { useEffect, useState } from "react";
import { getTeams } from "../../helpers/firebase";
import Teams from "./Teams";

const ExpandedTagsColumns = ({
  teamName,
  isExpanded,
  users,
  setUsers,
  userToUpdate,
}) => {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isExpanded) {
      setLoading(true);
      getTeams(teamName)
        .then((teams) => {
          setTeams(teams);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isExpanded]);

  if (loading) {
    return (
      <ul>
        <div>Loading...</div>
      </ul>
    );
  }

  if (teams.length === 0) {
    return (
      <ul className="danger-bg">
        <span>No Teams</span>
      </ul>
    );
  }
  return (
    <ul>
      {teams.map((team) => {
        return (
          <Teams
            key={team.id}
            club={team}
            teamName={teamName}
            isExpanded={isExpanded}
            users={users}
            setUsers={setUsers}
            userToUpdate={userToUpdate}
            loading={loading}
          />
        );
      })}
    </ul>
  );
};

export default ExpandedTagsColumns;
