// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  Timestamp,
  doc,
  setDoc,
  updateDoc,
  deleteField,
  orderBy,
  query,
  deleteDoc
} from 'firebase/firestore';

// Get Firestore creds
import firebaseConfigEnv from './firebase_config.json';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig =
  process.env.REACT_APP_ENV === 'production'
    ? firebaseConfigEnv.prod
    : firebaseConfigEnv.dev;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const loginWithEmailAndPassword = async (email, password) => {
  return signInWithEmailAndPassword(auth, email, password)
    .then((resp) => {
      console.log('logged in', resp);
      return resp;
    })
    .catch((err) => err.message);
};

const logout = () => {
  signOut(auth);
};

const getUsers = async () => {
  const querySnapshot = await getDocs(collection(db, 'customer'));
  let clubs = [];
  querySnapshot.forEach((doc) => {
    clubs.push(doc.id);
  });

  return clubs;
};

const getTeams = async (club) => {
  const querySnapshot = await getDocs(collection(db, club));
  let teams = [];
  querySnapshot.forEach(async (doc) => {
    teams.push({
      id: doc.id,
      data: doc.data()
    });
  });

  return teams;
};

const getActiveTeams = async (club) => {
  const querySnapshot = await getDocs(collection(db, club));
  let teams = [];
  for (let i = 0; i < querySnapshot.docs.length; i++) {
    const doc = querySnapshot.docs[i];
    const teamPlayers = await getTeamPlayers(club, doc.id);

    if (doc.exists && teamPlayers.length > 0) {
      teams.push({
        id: doc.id,
        data: {
          ...doc.data(),
          players: teamPlayers
        }
      });
    }
  }

  return teams;
};

const getTeamSubCollection = async (club, team) => {
  const querySnapshot = await getDocs(
    collection(db, `${club}/${team}/seasons`)
  );
  let teams = [];
  querySnapshot.forEach((doc) => {
    teams.push({
      ...doc.data(),
      id: doc.id
    });
  });

  return teams;
};

const createInitialSeason = async (club, team) => {
  const docRef = await addDoc(collection(db, `${club}/${team}/seasons`), {
    startDate: Timestamp.now(),
    endDate: Timestamp.now()
  });

  return docRef.id;
};

const updateSeason = async (club, team, seasonId, data) => {
  const seasonRef = doc(db, `${club}/${team}/seasons/${seasonId}`);

  return setDoc(seasonRef, data, { merge: true });
};

const parseDateToFirestore = (date) => {
  return Timestamp.fromDate(new Date(date));
};

const getTeamTagsCollection = async (club, team) => {
  const querySnapshot = await getDocs(collection(db, `${club}/${team}/config`));
  let tags = [];
  querySnapshot.forEach((doc) => {
    tags.push({
      ...doc.data()
      // id: doc.id,
    });
  });

  return tags;
};

const createInitialTag = async (club, team, newData) => {
  const docRef = await setDoc(doc(db, `${club}/${team}/config`, 'tags'), {
    tags: newData
  });

  return docRef ? true : false;
};
const updateTags = async (club, team, newData) => {
  const configRef = doc(db, `${club}/${team}/config/tags`);
  return await updateDoc(configRef, { tags: newData }, { merge: true });
};

const getTeamPlayers = async (club, team) => {
  const querySnapshot = await getDocs(
    collection(db, `${club}/${team}/players`)
  );
  let players = [];
  querySnapshot.forEach((doc) => {
    players.push({
      ...doc.data()
      // id: doc.id,
    });
  });

  return players;
};

const addTeamPlayer = async (club, team, player) => {
  try {
    console.log('club team', club, team, player);
    const playersRef = collection(db, `${team}/${club}/players`);
    const playerRef = await addDoc(playersRef, player);

    const updatedPlayer = {
      ...player,
      id: playerRef.id
    };

    const playerRefId = doc(db, `${team}/${club}/players/${playerRef.id}`);
    await updateDoc(playerRefId, {
      ...updatedPlayer,
      id: playerRef.id
    });
    console.log('playerRef.id', playerRef.id);
    return playerRef.id;
  } catch (err) {
    console.log('err', err);
  }
};

const addTeamPlayers = async (club, team, players) => {
  for (const player of players) {
    await addTeamPlayer(club, team, player);
  }
};

const deleteTeamPlayers = async (club, team) => {
  const playersRef = collection(db, `${team}/${club}/players`);
  const querySnapshot = await getDocs(playersRef);
  querySnapshot.forEach(async (doc) => {
    await deleteDoc(doc.ref);
  });
};

const getTeamGamesWithoutReports = async (club, team) => {
  const gamesCollection = query(
    collection(db, club, team, 'games'),
    orderBy('date', 'asc')
  );

  const querySnapshot = await getDocs(gamesCollection);
  let games = [];
  querySnapshot.forEach(async (doc) => {
    // get report collection from game doc
    // if (!doc.get('status') && doc.get('date') !== undefined) {
    games.push({
      ...doc.data(),
      date:
        doc.get('date') instanceof Timestamp
          ? doc.get('date').toDate().toDateString()
          : doc.get('date'),
      id: doc.id
    });
    // }
  });

  return games;
};

const getPlayersByTeam = async (club, team) => {
  const playersCollection = query(collection(db, club, team, 'players'));

  const querySnapshot = await getDocs(playersCollection);
  let players = {};
  querySnapshot.forEach(async (doc) => {
    // get report collection from game doc
    if (!doc.get('deleted')) {
      players[doc.id] = {
        id: doc.id,
        name: doc.get('name'),
        email: doc.get('email'),
        tag: doc.get('tag')
      };
    }
  });

  return players;
};

const getPremiumUsers = async (club, team) => {
  const querySnapshot = await getDocs(collection(db, `users`));
  let premiumUsers = [];
  querySnapshot.forEach((doc) => {
    premiumUsers.push({
      ...doc.data(),
      id: doc.id
    });
  });

  return premiumUsers;
};
const updatePremiumStatus = async (updatedUser) => {
  const { id } = updatedUser;
  const configRef = doc(db, `users/${id}`);
  // return await updateDoc(configRef, updatedUser);
  return await updateDoc(configRef, updatedUser, { merge: true });
};

const removePremiumStatus = async (updatedUser) => {
  const { id } = updatedUser;
  const configRef = doc(db, `users/${id}`);
  return await updateDoc(configRef, {
    premium_until: deleteField()
  });
};

const addGeneralStatusStats = async (club, team, gameId, data) => {
  const configRef = doc(db, `${club}/${team}/games/${gameId}`);
  return updateDoc(configRef, { status: data }, { merge: true });
};

const addReportCollection = async (club, team, gameId, data, generalStats) => {
  const docRef = await setDoc(
    doc(db, `${club}/${team}/games/${gameId}/report`, 'stats'),
    {
      ...data.stats
    },
    {
      merge: true
    }
  );
  await setDoc(doc(db, `${club}/${team}/games/${gameId}/report`, 'series'), {
    ...data.series
  });
  // await setDoc(doc(db, `${club}/${team}/games/${gameId}`, 'stats'), {
  //   ...generalStats
  // });

  // const configRef = doc(db, `${club}/${team}/games/${gameId}`);
  // await updateDoc(configRef, { status: generalStats }, { merge: true });
  await addGeneralStatusStats(club, team, gameId, generalStats);
  return docRef ? true : false;
};

const addReportStatCollection = async (club, team, gameId, statName, data) => {
  const docRef = await setDoc(
    doc(db, `${club}/${team}/games/${gameId}/report`, statName),
    {
      team: data.team,
      players: {}
    }
  );

  for (const [playerId, playerData] of Object.entries(data.players)) {
    const playerStatRef = doc(
      db,
      `${club}/${team}/games/${gameId}/report/stats`
    );

    await setDoc(
      playerStatRef,
      {
        players: {
          [playerId]: playerData
        }
      },
      { merge: true }
    ).catch((error) => {
      console.error(`Error updating player ${playerId}:`, error);
      // Handle the error or break out of the loop as necessary
    });

    // Log or perform actions after each successful update if needed
    console.log(`Updated stats for player ${playerId}`);
  }
  return docRef ? true : false;
};

export {
  auth,
  db,
  loginWithEmailAndPassword,
  logout,
  getUsers,
  getTeams,
  getTeamSubCollection,
  createInitialSeason,
  updateSeason,
  parseDateToFirestore,
  getTeamTagsCollection,
  createInitialTag,
  updateTags,
  getTeamPlayers,
  getPremiumUsers,
  updatePremiumStatus,
  removePremiumStatus,
  getActiveTeams,
  getTeamGamesWithoutReports,
  addReportCollection,
  getPlayersByTeam,
  addReportStatCollection,
  addGeneralStatusStats,
  addTeamPlayers,
  deleteTeamPlayers
};
